///////////// Variables
//##### Color system
$sirc-blue1:  #000091; //Principale
$sirc-blue1-op:  rgba(97, 80, 255, 0.15); //Principale - faible opacité
$sirc-blue2:  #3558A2; //Principale médium
$sirc-blue3:  #0000C4; //Principale foncée
$sirc-light-blue: #7AB1E8;

$sirc-green1: #22712B;
$sirc-green2: #17471d;
$sirc-red1:   #db272a;
$sirc-red2:   #ff2b2e;
$sirc-orange1: #FF9575;
$sirc-orange2: #FBDBAB;
$sirc-orange3: #b35b11;

$sirc-black:  #202328;
$sirc-grayddd:#222222;
$sirc-graydd: #444444;
$sirc-grayd:  #555555;
$sirc-graymd:  #8F8F8F;
$sirc-graym:  #AAAAAA;
$sirc-grayml:  #DBDBDB;
$sirc-grayl:  #E6E6E6;
$sirc-grayll: #F5F5F5;
$white:       #FFF;

$sirc-dark-gray: #6C5D55;

$colors: (
  "sirc-blue1": $sirc-blue1, "sirc-blue2": $sirc-blue2, "sirc-blue3": $sirc-blue3,
  "sirc-green1": $sirc-green1, "sirc-green2": $sirc-green2,
  "sirc-red1": $sirc-red1, "sirc-red2": $sirc-red2, "sirc-orange1": $sirc-orange1,
  "sirc-black": $sirc-black,
  "sirc-grayddd": $sirc-grayddd, "sirc-graydd": $sirc-graydd, "sirc-grayd": $sirc-grayd,
  "sirc-graymd": $sirc-graymd, "sirc-graym": $sirc-graym, "sirc-grayml": $sirc-grayml,
  "sirc-grayl": $sirc-grayl, "sirc-grayll": $sirc-grayll,
  "white": $white
);


$primary:     $sirc-blue1;
$secondary:   $sirc-blue2;
$success:     $sirc-green1;
$info:        $sirc-blue2;
$warning:     $sirc-orange3;
$danger:      $sirc-red1;
$alerte:      $sirc-red2;
$theme-colors: (
  "primary": $primary, "success": $success, "info": $info,
  "warning": $warning, "danger":  $danger
);


//##### Spacing
$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    25: 0.25rem,
    50: 0.5rem,
    75: 0.75rem,
    1: $spacer,
    2: $spacer * 2,
    3: $spacer * 3,
    4: $spacer * 4,
    5: $spacer * 5
  ),
  $spacers
);


//##### Body
// Settings for the `<body>` element.
$body-bg:                   $sirc-grayll;
$body-color:                $sirc-grayd;


//##### Links
// Style anchor elements.
$link-color:                $primary;
$link-hover-color:          $secondary;
//$link-decoration:           none !default;
//$link-hover-decoration:     underline;


//##### Paragraphs
// Style p element.
$paragraph-margin-bottom:   $spacer;


//##### Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
/*
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
*/

//##### Grid containers
// Define the maximum width of `.container` for different screen sizes.
/*
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) !default;
@include _assert-ascending($container-max-widths, "$container-max-widths");
*/

//##### Grid columns
// Set the number of columns and specify the width of the gutters.
//$grid-columns:        12;
//$grid-gutter-width:   30px;


//##### Components
// Define common padding and border radius sizes and more.
$border-width:                2px;
//$border-color:                $gray-300 !default;
$border-radius:               .75rem;
//$border-radius-lg:            .3rem !default;
//$border-radius-sm:            .2rem !default;
/*
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;
$caret-width:                 .3em !default;
$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;
*/


//##### Fonts
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Marianne", arial, sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-scale:     1rem;
$font-size-base:      1.1rem; // Assumes the browser default, typically `16px`
$font-size-medium:    1.25rem; // Assumes the browser default, typically `16px`
$font-size-big:       1.4rem;
$font-size-small:     0.9rem;
$font-size-tiny:      0.85rem;
$font-size-title:     4rem;
$font-size-title-sm:  3.25rem;
$font-size-subtitle:  1.4rem;
$font-size-icon-btn:     2rem;
$font-size-icon-btn-sm:  1.5rem;
$font-size-icon-btn-big: 2.5rem;
$font-size-big-icon:  5rem;

//$font-weight-light:           300 !default;
//$font-weight-normal:          400 !default;
//$font-weight-bold:            700 !default;
//$font-weight-base:            $font-weight-normal !default;
//$line-height-base:            1.5 !default;

$h1-font-size:                2rem;
$h2-font-size:                1.8rem;
$h3-font-size:                1.75rem;
$h4-font-size:                1.4rem;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      $spacer;
$headings-font-weight:        bold;
$headings-color:              $sirc-graydd;
//$headings-line-height:        1.2 !default;


//##### HR
//$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             1px;
$hr-margin-y:                 2rem;



//##### Buttons
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               0.9rem;
$btn-padding-x:               2.3rem;
//$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            0.9rem;
$btn-padding-x-sm:            0.5rem;
//$btn-line-height-sm:          $input-btn-line-height-sm !default;

$btn-padding-y-lg:            0.9rem;
$btn-padding-x-lg:            2.3rem;
//$btn-line-height-lg:          $input-btn-line-height-lg !default;

//$btn-border-width:            $input-btn-border-width !default;

//$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              none;
$btn-focus-width:             none;
$btn-focus-box-shadow:        none;
$btn-disabled-opacity:        0.25;
$btn-active-box-shadow:       none;

//$btn-link-disabled-color:     $gray-600 !default;
//$btn-block-spacing-y:         .5rem !default;



//##### Dropdowns
// Dropdown menu container and contents.
$dropdown-bg:                       $white;
$dropdown-link-hover-bg:            $sirc-blue3;
$dropdown-link-active-bg:           $sirc-blue3;
$dropdown-link-color:               $primary;
$dropdown-link-hover-color:         $sirc-grayll;
$dropdown-link-active-color:        $sirc-grayll;
$dropdown-padding-y:                1rem;

/*
$dropdown-min-width:                10rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-divider-bg:               $gray-200 !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;
$dropdown-link-disabled-color:      $gray-600 !default;
$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;
$dropdown-header-color:             $gray-600 !default;
*/


//##### Tables
//$table-cell-padding:          .75rem !default;
//$table-cell-padding-sm:       .3rem !default;
$table-bg:                    $white;
//$table-accent-bg:             rgba($black, .05) !default;
//$table-hover-bg:              rgba($black, .075) !default;
//$table-active-bg:             $table-hover-bg !default;
$table-border-width:          0px;
$table-border-color:          $white;
$table-head-bg:               unset;
$table-head-color:            $sirc-grayddd;
