/** Marianne */

@font-face {
  font-family: 'Marianne';
  font-style: normal;
  src: local('Marianne-Regular'), url('Marianne/Marianne-Regular.woff2') format('woff2'), url('Marianne/Marianne-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Marianne';
  font-style: italic;
  src: local('Marianne-Regular_Italic'), url('Marianne/Marianne-Regular_Italic.woff2') format('woff2'), url('Marianne/Marianne-Regular_Italic.woff') format('woff');
}

@font-face {
  font-family: 'Marianne';
  font-style: normal;
  font-weight: bold;
  src: local('Marianne-Bold'), url('Marianne/Marianne-Bold.woff2') format('woff2'), url('Marianne/Marianne-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Marianne';
  font-style: italic;
  font-weight: bold;
  src: local('Marianne-Bold_Italic'), url('Marianne/Marianne-Bold_Italic.woff2') format('woff2'), url('Marianne/Marianne-Bold_Italic.woff') format('woff');
}

// Material Icons
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIcons-Regular'), url(MaterialIcons/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIconsOutlined-Regular'), url(MaterialIcons/MaterialIconsOutlined-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIconsRound-Regular'), url(MaterialIcons/MaterialIconsRound-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIconsSharp-Regular'), url(MaterialIcons/MaterialIconsSharp-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  src: local('MaterialIconsTwoTone-Regular'), url(MaterialIcons/MaterialIconsTwoTone-Regular.otf) format('opentype');
}
