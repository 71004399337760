$icomoon-font-family: "sirc-icomoon" !default;
$icomoon-font-path: "icomoon" !default;

$ico-upload-path1: "\e900";
$ico-upload-path2: "\e901";
$ico-upload-path3: "\e902";
$ico-creer-demande-path1: "\e903";
$ico-creer-demande-path2: "\e904";
$ico-creer-demande-path3: "\e905";
$ico-creer-demande-path4: "\e906";
$ico-creer-demande-path5: "\e907";
$ico-creer-demande-path6: "\e908";
$ico-creer-demande-path7: "\e909";
$ico-ouvrir-demande-path1: "\e90a";
$ico-ouvrir-demande-path2: "\e90b";