//##### HEADER #######
header.sirc-header {
  @media (min-width: map-get($grid-breakpoints, "md")) {
    background-position: -2%;
    background-size: 27rem 27rem;
  }

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    .header-cdt-num-widget {
      display: none;
    }
  }
}

app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #contenu {
    flex: 1 0 auto;
  }

  #pied-de-page {
    flex-shrink: 0;
  }
}

//##### FOTER #######
footer.sirc-footer {
  background: $white;

  .footer-menu-container {
    .footer-menu {
      @media (min-width: map-get($grid-breakpoints, "sm")) {
        justify-content: center !important;
      }

      .link-container {
        @media (max-width: map-get($grid-breakpoints, "md")) {
          padding: 0.5rem;
          font-size: $font-size-tiny;
          border: none;
        }

        padding: 1rem;
        font-size: $font-size-scale;
        border-right: 2px solid $sirc-grayll;

        &:last-child {
          border: none;
        }
      }
    }
  }

  .footer-info-version {
    @extend .italic;
    text-align: center;
    font-size: 0.7rem;
  }
}

//##### FIL ARIANE #######
div.filariane-container {
  @extend .flex-wrap, .d-flex, .align-items-center, .mb-1, .mb-sm-3;
  font-size: $font-size-small;

  div.filariane-item {
    @extend .flex-wrap, .d-flex, .align-items-center;

    i {
      cursor: default;
      margin-top: 4px;
      font-size: $font-size-base;
    }

    > * {
      margin-right: 0.5rem;
    }
  }
}

//##### LOADER #####
.sk-circle {
  margin: 10% auto;
  width: 60px;
  height: 60px;
  position: relative;
}

.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle .sk-child:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: $primary;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}

.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

//##### TOASTER #####
#toast-container {
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    top: 0;
    right: 0;
  }
}

#toast-container > div {
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    width: 100%;
  }

  width: 400px;
}

.toast-success {
  background-color: $sirc-green1 !important;
}

.toast-info {
  background-color: $sirc-blue2 !important;
}

.toast-warning {
  background-color: $sirc-orange3 !important;
}

//##### BODY #######
div.sirc-page-container {
  min-height: 500px;
}

div.sirc-page-body {
  background: unset;
  min-height: 500px;
}

// Container de boutons situé au-dessus d'un element de type grille, liste...
.buttons-container-header,
.buttons-container-footer {
  @extend .pt-1,
    .pb-1,
    .d-flex,
    .flex-wrap,
    .align-items-center,
    .justify-content-end;

  > * {
    margin-left: 5px;
  }

  .expand-btns {
    @extend .mt-1, .mt-sm-0, .d-flex, .align-items-center;

    > * {
      margin-left: 5px;
    }
  }
}

.buttons-container {
  @extend .d-flex, .justify-content-end;
  flex-wrap: wrap;

  button:not(:first-child) {
    margin-left: 1rem;
  }
}

//##### MODALES #####

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
  color: black;
}

.sirc-modale {
  @media (max-width: map-get($grid-breakpoints, "sm")) {
    max-width: 100% !important;
    height: 100% !important;
  }

  .mat-mdc-dialog-container {
    @media (max-width: map-get($grid-breakpoints, "sm")) {
      border-radius: 0px;
    }
  }

  .sirc-modale-titre-container {
    background: $sirc-grayll;
    border-bottom: 2px solid $sirc-grayml;
    margin: -24px;
    margin-bottom: 0rem;
    padding: 1rem;

    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 0px;
    }
  }

  .sirc-modale-content-container {
    @media (max-width: map-get($grid-breakpoints, "sm")) {
      max-height: initial;
    }

    @extend .pt-1, .pb-0, .pb-sm-1;
    display: block;
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 65vh;
    overflow: auto;
  }
}

.sirc-modale-boutons-container {
  @extend .pb-1, .d-flex, .justify-content-end;
  flex-wrap: wrap;
  margin-bottom: -24px; // Material
  margin-top: 0rem;

  button:not(:first-child) {
    margin-left: 1rem;
  }
}

.loading-modale-text {
  margin-bottom: 1rem;
}

.big-info {
  font-size: $font-size-title-sm;
}

.sirc-modale-accueil {
  @extend .sirc-modale;
  min-width: 60%;
}

///##### PAGER #####
.sirc-pager {
  font-size: 1.1rem;

  &.pager-desktop {
    margin: 0;
    background-color: $white;
    border: 2px solid $sirc-grayl;

    .pager-result-infos {
      font-size: $font-size-scale;
      padding: 0;
    }

    .pager-select-rows {
      outline: none;

      > select {
        width: 100%;
        padding: 0.25rem;
        height: 2.5rem;
        color: $sirc-grayd;
        border: 2px solid $sirc-grayml;
        outline: none;
        background-color: $white;
      }
    }

    .pager-pages .pager-prev {
      margin-right: 0.5rem;
    }

    .pager-pages .pager-next {
      margin-left: 0.5rem;
    }

    .pager-infos {
      .pager-infos-page {
        &:last-child {
          .pager-dot {
            display: none;
          }
        }

        .pager-page {
          cursor: pointer;
          text-decoration: none;
          &.pager-page-actived {
            color: $primary;
            font-weight: bold;
          }
          &:hover {
            text-decoration: underline;
          }
        }

        .pager-dot {
          font-size: 0.5rem;
          margin-right: 1rem;
          margin-left: 1rem;
          opacity: 0.25;
        }
      }
    }
  }

  .pager-arrow {
    font-size: $font-size-icon-btn;
    cursor: pointer;
    padding: 0 0.5rem;
    color: $sirc-blue1;

    &:hover {
      color: $primary;
    }
  }

  .pager-btn-disabled {
    opacity: 0.25;
    cursor: default;
  }
}

///##### TABLE #####
table.sirc-table {
  border: 1px solid $sirc-grayl;

  thead {
    &.thead-gray {
      background: $sirc-grayll;
      cursor: default;
    }

    border: 1px solid $sirc-grayl;

    > tr {
      th {
        font-size: 1rem;
        color: $sirc-graydd;
        padding: 0.75rem;

        &.actions {
          min-width: 80px;
        }

        > div.sortable {
          > i {
            color: $sirc-graymd;
          }

          @extend .d-flex, .align-items-center, .justify-content-around;
          cursor: pointer;
        }
      }
    }
  }

  tbody > tr {
    cursor: default;
    font-size: $font-size-scale;

    &:hover {
      background-color: #6150ff26 !important;
    }

    &:hover > * {
      background-color: transparent !important;
    }

    &.odd {
      background: $sirc-grayll;
    }

    &.odd > * {
      background: transparent;
    }

    td {
      color: $sirc-grayd;
      padding: 0.75rem;
    }

    td.td-btns {
      @extend .d-flex, .justify-content-end;
    }
  }
}

/** Administration - paramètres */
.sirc-radio-group {
  display: inline-flex;
  flex-direction: line;

  > mat-radio-button {
    margin: 0 10px 0 10px;
    .mdc-label {
      font-size: 1.1rem;
      padding-left: 0.5rem;
    }
  }
}

// MESSAGE INFOS
.message-info-block {
  .alert {
    border-radius: 0.25rem;
  }

  .INFO {
    border: 2px solid $primary;
  }

  .WARN {
    border: 2px solid $warning;
  }

  .ALERT {
    border: 2px solid $danger;
  }

  .ql-editor {
    white-space: normal;
  }
}

// ACCUEIL
.acc-btn-principal-info {
  text-align: center;
}

.acc-actions-rapides {
  .ar-item-container {
    .ar-icone {
      text-align: center;
      width: 75px;

      i.material-icons {
        cursor: pointer;
        font-size: 3rem;
      }
    }

    .ar-item-infos-container {
      .ar-item-titre {
        font-size: $font-size-medium;
      }

      .ar-item-desc {
        @extend .italic;
        font-size: $font-size-tiny;
        margin-top: 0.5rem;
      }
    }
  }
}

.acc-voiraussi-item-container {
  i {
    cursor: pointer;
    font-size: $font-size-icon-btn-big;
  }

  .voiraussi-lien {
    @extend .p-0, .ps-1, .p-sm-1 !optional;
    margin-bottom: 0.25rem;
  }
}

.warning-bloc-info {
  border: 2px solid $warning;
  background-color: $sirc-orange2;
  border-radius: 5px;
}

.basic-bloc-info {
  border: 2px solid $sirc-blue3;
  background-color: $sirc-light-blue;
  border-radius: 5px;
}

/*TODO(mdc-migration): DO LATER RADIO. */
.mat-mdc-list-item,
.mat-radio-container {
  &:focus {
    outline: 2px solid $sirc-black !important;
  }
}

button:focus {
  outline: 2px solid #fff !important;
  outline-offset: -3px;
}

.mat-icon {
  vertical-align: middle;
}
