@use "@angular/material" as mat;
@import "./palettes.scss";
@include mat.core();

$sirc-mat-app-primary: mat.define-palette($sirc-primary);
$sirc-mat-app-accent: mat.define-palette($sirc-primary);
$sirc-mat-app-warn: mat.define-palette($sirc-warn);
$sirc-mat-app-theme: mat.define-light-theme(
  $sirc-mat-app-primary,
  $sirc-mat-app-accent,
  $sirc-mat-app-warn
);

@include mat.all-component-themes($sirc-mat-app-theme);

$custom-typography: mat.define-typography-config(
  $font-family: '"Marianne", arial, sans-serif',
  $body-1: mat.define-typography-level(1.1rem, 1.1rem, 400),
);
@include mat.all-component-typographies($custom-typography);

// CARD MATERIAL
.mat-mdc-card {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-card-outlined {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
  border-left: none !important;
}

.mat-mdc-card-content {
  padding: 0 !important;
}

// SURCHARGE MATERIAL
/* TODO(mdc-migration): DO LATER TABS */

.hidden {
  mat-ink-bar {
    visibility: hidden !important;
  }
}

/* TODO(mdc-migration): DO LATER CHECKBOX */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mdc-tooltip__surface {
  max-width: 320px !important;
  background: $sirc-blue3 !important;
  font-size: $font-size-scale !important;
  line-height: 1.5rem !important;
  padding: 0.5rem !important;
  max-height: none !important;
  height: auto !important;
  overflow: auto !important;
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: normal;
  border-bottom: 1px solid #0000001f;
}
.mat-mdc-tab-label-container {
  flex-grow: 0 !important;
}

.mat-mdc-tab .mdc-tab__text-label {
  color: $sirc-graydd !important;
}

.sirc-mat-tab-titre {
  &.error {
    color: $danger;
  }
}

mat-tab-group.error {
  .mdc-tab-indicator__content--underline {
    border-color: $danger !important;
  }
}

.mat-mdc-form-field {
  width: 100%;

  // for Marianne font family, letter 'q, g, y, q, j' is not displayed entirely by default
  line-height: 1.5rem;
}

/* TODO(mdc-migration): DO LATER BUTTON */
.mat-mdc-button.mat-blue:not(.mat-mdc-button[disabled]) {
  background-color: $sirc-blue1;
  color: $white !important;
}

.mat-mdc-button {
  /* TODO(mdc-migration): DO LATER BUTTON */
  &:hover:not(.mat-mdc-button[disabled]) {
    background-color: $sirc-blue3;
    color: $white;
  }
}

// to display multiple matSuffix in one line
/* TODO(mdc-migration): DO LATER form-field */
.mat-form-field-suffix {
  display: inline-flex !important;
  align-items: baseline;
}

.mat-mdc-text-field-wrapper {
  padding-right: 16px !important;
}

.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none !important;
}

.mat-form-field-subscript-wrapper {
  position: relative !important;
}

.mat-mdc-form-field {
  margin-bottom: 1rem;

  &.mat-form-field-invalid {
    margin-bottom: 4rem;
  }
  position: relative;
}

.mat-mdc-form-field-error {
  color: #b60000;
  position: relative;
  top: -20px;
  margin-top: 0.5rem;
}

mat-label {
  color: #777677;
}

/* TODO(mdc-migration): DO LATER BUTTON */
.mat-radio-button.mat-radio-disabled .mat-radio-label-content,
.mat-mdc-button.mat-mdc-button-disabled.mat-mdc-button-disabled,
.mat-input-element:disabled,
.mat-mdc-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.6) !important;
}

.mat-mdc-dialog-container {
  height: unset !important;
}

/****************************
* [RGAA 8.2] Disabled style *
*****************************/
[aria-disabled="true"] {
  @mixin button($rules) {
    @at-root #{$rules}#{&} {
      cursor: default;
      color: rgba(0, 0, 0, 0.6) !important;
      pointer-events: none;
      @include mat.elevation(0);
    }

    @at-root #{$rules}:not(.mat-mdc-button-disabled)#{&}:active {
      @include mat.elevation(0);
    }
  }

  @include button(".mat-button");
  @include button(".mat-raised-button");
  @include button(".mat-stroked-button");
  @include button(".mat-flat-button");
  @include button(".mat-icon-button");
  @include button(".mat-fab");
  @include button(".mat-mini-fab");
}
