body,
html {
  height: 100%;
  font-family: "Marianne", arial, sans-serif;
}

.sirc-block {
  @extend .p-1;
  border: 2px solid $sirc-grayl;
  border-radius: 12px;
  background-color: $white;
}

.sirc-title-block {
  border: 1px solid $sirc-blue1;
  background-color: $sirc-blue1;
  margin-top: 2rem;
  padding: 1rem !important;

  > .row .search-title {
    color: white;
  }

  > .row .filter-icon {
    color: white;
  }
}

.sirc-block-p2 {
  @extend .p-1, .p-sm-2, .sirc-block;
}

.sirc-block-recherche-p2 {
  @extend .p-1, .sirc-block;
  border-radius: 0;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.block-ligne-border {
  border-top: 1px outset $sirc-grayml !important;
}

.border-gray {
  border: 1px solid $sirc-graymd;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.white {
  color: $white;
}

.primary {
  color: $sirc-blue1;
  background-color: $white;

  &:hover {
    background-color: $sirc-blue3;
  }
}

.success {
  color: $success;

  &:hover {
    color: $sirc-green2;
  }
}

.danger {
  color: $danger;

  &:hover {
    color: $sirc-red2;
  }
}

.hidden {
  visibility: hidden;
}

.inactif {
  opacity: 0.25;
}

.version {
  font-size: $font-size-tiny;
}

// ############ TOASTER ##############
.toast-container {
  .toast {
    .toast-close-button {
      button {
        color: white;
        border: 0;
        background-color: transparent;
      }
    }
  }
}

// ############# LIENS ###############
.fake-link {
  color: $primary;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

// ############# BUTTON ###############
.btn {
  white-space: normal;

  @media (max-width: map-get($grid-breakpoints, "sm")) {
    width: 100%;
    padding: $btn-padding-y-sm $btn-padding-x-sm;
  }
}

.btn-sirc {
  @extend .btn, .btn-primary;
}

.btn-sirc-o {
  @extend .btn, .btn-outline-primary;
}

.btn-sirc-danger {
  @extend .btn, .btn-danger;
}

.btn-sirc-danger-o {
  @extend .btn, .btn-outline-danger;
}

.btn-sirc-success {
  @extend .btn, .btn-success;
}

.btn-sirc-success-o {
  @extend .btn, .btn-outline-success;
}

.btn-icon {
  @extend .d-inline-flex, .justify-content-center, .align-items-center;
  padding: 0.25rem;
  padding-right: 1rem;

  > i {
    margin-right: 0.75rem;
    font-size: 3rem;
  }
}

.btn-small {
  padding: 0.5rem 1rem;
  font-size: $font-size-scale;
  border-width: 1px;
}

.btn-icon-small {
  @extend .btn-small,
    .d-inline-flex,
    .justify-content-center,
    .align-items-center;

  > i {
    margin-right: 5px;
  }
}

button.no-outline {
  border: none !important;
}

i.is-btn {
  cursor: pointer;
  font-size: $font-size-icon-btn;
}

i.is-btn-small {
  cursor: pointer;
  font-size: $font-size-icon-btn-sm;
}

i.is-btn-big {
  cursor: pointer;
  font-size: $font-size-icon-btn-big;
}

.mat-mdc-button {
  color: $sirc-blue1;
  border: 1px solid !important;
  &:not(.mat-mdc-button[disabled]) {
    border-color: $sirc-blue1;
    width: max-content;
    letter-spacing: normal;
    color: $sirc-blue1;
    padding: 16px;
  }
  &.mat-mdc-button[disabled] {
    letter-spacing: normal;
    border-color: #00000099;
    width: max-content;
  }

  &:hover {
    color: $white !important;
  }
}

.dialog-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  h4 {
    padding: 0;
    margin-bottom: 20px;
  }
}

/*** FORMULAIRES **/
.mb-025 {
  margin-bottom: 0.25rem !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

div.sirc-fc-lg {
  @extend .col-12, .col-md-12, .mb-025, .d-flex, .flex-column;
}

div.sirc-fc-lg-2 {
  @extend .col-12, .col-md-12, .mb-2, .d-flex, .flex-column;
}

div.sirc-fc-md {
  @extend .col-12, .col-md-6, .mb-025, .d-flex, .flex-column;
}

div.sirc-fc-md-1 {
  @extend .col-12, .col-md-4, .mb-1, .d-flex, .flex-column;
}

div.sirc-fc-md-2 {
  @extend .col-12, .col-md-6, .mb-2, .d-flex, .flex-column;
}

div.sirc-fc-sm {
  @extend .col-12, .col-md-4, .mb-025, .d-flex, .flex-column;
  .mat-mdc-checkbox label {
    font-size: 16px;
  }
}

div.sirc-fc-sm-2 {
  @extend .col-12, .col-md-4, .mb-2, .d-flex, .flex-column;
}

div.sirc-fc-sm-nocol {
  @extend .col-12, .col-md-4, .mb-025, .d-flex;
  align-items: baseline;
}

div.sirc-fc-xs {
  @extend .col-6, .col-md-4, .mb-025, .d-flex, .flex-column;
}

div.sirc-fc-xs-2 {
  @extend .col-6, .col-md-4, .mb-2, .d-flex, .flex-column;
}

div.sirc-fc-xsm {
  @extend .col-4, .col-md-2, .mb-025, .d-flex, .flex-column;
}

div.sirc-fc-xsm-2 {
  @extend .col-4, .col-md-2, .mb-2, .d-flex, .flex-column;
}

div.sirc-fc-fill {
  @extend .col-12, .col-md, .mb-2, .d-flex, .flex-column;
  .mdc-form-field {
    font-size: 16px;
  }
}

div.sirc-fc-fill-2 {
  @extend .col-12, .col-md, .mb-2, .d-flex, .flex-column;
}

div.sirc-fc-fill-3 {
  @extend .col-12, .col-md, .d-flex, .flex-column;
  margin-bottom: 0.25rem !important;
}

label.sirc-label {
  &.error {
    color: $danger;
  }

  &.warn {
    color: $warning;
  }

  font-size: $font-size-scale;
  font-weight: bold;
  letter-spacing: -0.25px;
  margin-bottom: 0.5rem;
  margin-right: 16px;

  & > span.optionnel {
    font-style: italic;
    font-weight: 300;
  }
}

label.sirc-label-titre {
  &.error {
    color: $danger;
  }

  &.warn {
    color: $warning;
  }

  color: black;
  font-size: $font-size-medium;
  font-weight: 900;
  letter-spacing: -0.25px;

  & > span.optionnel {
    font-style: italic;
    font-weight: 300;
  }
}

label.sirc-info {
  &.error {
    color: $danger;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }

  &.warn {
    color: $warning;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
    font-weight: bold;
  }

  color: $sirc-graymd;
  font-size: $font-size-tiny;
  margin-top: 0.5rem;
}

div.sirc-date-field-container {
  @extend .d-flex, .align-items-center;

  .sirc-field {
    cursor: default;
  }

  mat-datepicker-toggle {
    color: $primary;
  }
}

input.sirc-field,
select.sirc-field,
textarea.sirc-field,
mat-select.sirc-field {
  color: $sirc-grayd;
  border: 2px solid $sirc-grayml;
  border-radius: 4px;
  height: 48px;
  min-width: 100px;
  max-width: 100%;
  padding: 10px;

  &::placeholder {
    opacity: 1;
    color: $sirc-graym;
    font-size: $font-size-scale;
  }

  &:hover {
    border: 2px solid $sirc-graym;
  }

  &:focus {
    border: 2px solid $primary;
    outline: none;
  }

  &.error {
    border: 2px solid $danger;
  }

  &.warn {
    border: 2px solid $warning;
  }

  &.mat-mdc-select-disabled {
    background: $sirc-grayl;
  }

  .mat-mdc-select-value {
    overflow: visible;
  }
}

textarea.sirc-field {
  height: 200px !important;
  white-space: normal;
}

.sirc-errors-container {
  @extend .d-flex, .flex-column;
  min-height: 30px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.sirc-label {
  margin-right: 0 !important;
}

.sirc-label,
.sirc-info,
.sirc-field {
  &.disabled,
  &:disabled {
    opacity: 0.65;
    background: none;
  }
}

.warn.ng-touched:not(.error):not(form):not(div),
.warn.ng-dirty:not(.error):not(form):not(div) {
  border: 2px solid $warning;
}

/* TODO(mdc-migration): DO LATER checkbox.*/
span.mat-checkbox-label {
  font-size: $font-size-scale;
  color: $sirc-grayd;
}

tr.no-result,
div.no-result {
  text-align: center;
  font-style: italic;

  > td,
  > p {
    padding: 2rem;
  }
}

.align-center {
  @extend .d-flex, .justify-content-center, .align-items-center;
}

.hint-form {
  font-style: italic;
  font-size: 0.813rem;
}

/**************************
* [RGAA 10.7] Focus rules *
***************************/
*:not(
    input,
    textarea,
    mat-select,
    .mat-calendar-body-cell,
    mat-sidenav,
    div[mattablabelwrapper],
    button[mattreenodetoggle],
    .mat-mdc-dialog-container,
    mat-option,
    .mat-select-panel,
    .mat-calendar-content,
    .panel-header
  ):focus {
  outline: 2px solid black !important;
}

.mat-mdc-select-panel {
  padding: 0 !important;
}

.mat-radio-button:not(.mat-radio-disabled).cdk-keyboard-focused
  .mat-radio-ripple,
.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused
  .mat-radio-ripple,
.mat-mdc-checkbox.cdk-keyboard-focused .mat-checkbox-ripple,
.mat-checkbox.cdk-program-focused .mat-checkbox-ripple {
  outline: 2px solid black !important;
  border-radius: 50%;
}

.mat-expansion-panel-header:focus,
.mat-mdc-menu-content a:focus,
.mat-menu-content button:focus {
  outline-offset: -2px;
}

.mdc-text-field--outlined:not(.mdc-text-field--invalid)
  .mdc-notched-outline
  > * {
  border-width: 2px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--invalid):not(
    .mdc-text-field--focused
  ):not(.mdc-text-field--disabled):not(.mdc-text-field:hover) {
  .mdc-notched-outline > * {
    border-color: rgba(0, 0, 0, 0.12) !important;
    border-width: 1px !important;
  }
}
