// Externalized configuration file for SIRC2 custom style

/* Imports - Bootstrap and override variables */
@import 'partials/variables';
@import 'node_modules/bootstrap/scss/bootstrap';

/* Imports - others*/
// L'import des éléments suivants est fait dans le fichier angular.json : MaterialIcon, Toaster...
@import 'partials/material.scss';
/** Wysiwyg quill editor**/
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import 'partials/quill_custom.scss';


/* SIRC2 STYLE - GLOBAL */
@import 'partials/sirc_global_style.scss';
/* PAGES */
@import 'partials/sirc_style_commun.scss';
@import 'partials/sirc_page_espace_documentaire.scss';
@import 'partials/sirc2i_page_administration.scss';
@import 'partials/sirc2i_page_demande.scss';


// regular style toast
@import 'ngx-toastr/toastr';
