.ql-line-space-top-1-2 {
  margin-top: 4px !important;
}

.ql-line-space-top-1-4 {
  margin-top: 6px !important;
}

.ql-line-space-top-1-5 {
  margin-top: 8px !important;
}

.ql-line-space-top-1-6 {
  margin-top: 10px !important;
}

.ql-line-space-top-1-8 {
  margin-top: 14px !important;
}

.ql-line-space-top-2-0 {
  margin-top: 18px !important;
}

.ql-line-space-bottom-1-2 {
  margin-bottom: 4px !important;
}

.ql-line-space-bottom-1-4 {
  margin-bottom: 6px !important;
}

.ql-line-space-bottom-1-5 {
  margin-bottom: 8px !important;
}

.ql-line-space-bottom-1-6 {
  margin-bottom: 10px !important;
}

.ql-line-space-bottom-1-8 {
  margin-bottom: 14px !important;
}

.ql-line-space-bottom-2-0 {
  margin-bottom: 18px !important;
}

.ql-toolbar {
  .ql-line-space-top,
  .ql-line-space-bottom {
    width: 50px;

    .ql-picker-label::before,
    .ql-picker-item::before {
      content: '1.0';
    }

    .ql-picker-label[data-value='1-2']::before,
    .ql-picker-item[data-value='1-2']::before {
      content: '1.2';
    }

    .ql-picker-label[data-value='1-4']::before,
    .ql-picker-item[data-value='1-4']::before {
      content: '1.4';
    }

    .ql-picker-label[data-value='1-5']::before,
    .ql-picker-item[data-value='1-5']::before {
      content: '1.5';
    }

    .ql-picker-label[data-value='1-6']::before,
    .ql-picker-item[data-value='1-6']::before {
      content: '1.6';
    }

    .ql-picker-label[data-value='1-8']::before,
    .ql-picker-item[data-value='1-8']::before {
      content: '1.8';
    }

    .ql-picker-label[data-value='2-0']::before,
    .ql-picker-item[data-value='2-0']::before {
      content: '2.0';
    }
  }
}
