@import "fonts-icomoon-variables";
@import "variables.scss";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ooroqg') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ooroqg') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ooroqg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

.sirc-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-upload .path1 {
  &:before {
    content: $ico-upload-path1;
    color: $sirc-blue1;
  }
}
.ico-upload .path2 {
  &:before {
    content: $ico-upload-path2;
    margin-left: -0.7724609375em;
    color: $sirc-blue1;
  }
}
.ico-upload .path3 {
  &:before {
    content: $ico-upload-path3;
    margin-left: -0.7724609375em;
    color: $sirc-green1;
  }
}

.ico-creer-demande .path1 {
  &:before {
    content: $ico-creer-demande-path1;
    color: $sirc-blue1;
  }
}
.ico-creer-demande .path2 {
  &:before {
    content: $ico-creer-demande-path2;
    margin-left: -0.8916015625em;
    color: $sirc-blue1;
  }
}
.ico-creer-demande .path3 {
  &:before {
    content: $ico-creer-demande-path3;
    margin-left: -0.8916015625em;
    color: $sirc-blue1;
  }
}
.ico-creer-demande .path4 {
  &:before {
    content: $ico-creer-demande-path4;
    margin-left: -0.8916015625em;
    color: $sirc-blue1;
  }
}
.ico-creer-demande .path5 {
  &:before {
    content: $ico-creer-demande-path5;
    margin-left: -0.8916015625em;
    color: $sirc-blue1;
  }
}
.ico-creer-demande .path6 {
  &:before {
    content: $ico-creer-demande-path6;
    margin-left: -0.8916015625em;
    color: $sirc-blue1;
  }
}
.ico-creer-demande .path7 {
  &:before {
    content: $ico-creer-demande-path7;
    margin-left: -0.8916015625em;
    color: $sirc-green1;
  }
}
.ico-ouvrir-demande .path1 {
  &:before {
    content: $ico-ouvrir-demande-path1;
    color: $sirc-blue1;
  }
}
.ico-ouvrir-demande .path2 {
  &:before {
    content: $ico-ouvrir-demande-path2;
    margin-left: -0.9375em;
    color: $sirc-green1;
  }
}

